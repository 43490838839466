// src/Servicios.js
import React from 'react';
import './Servicios.css';
// import MX_6 from '../../imagenes/Valores.jpg';

const Servicios = () => {
    return (
        <div>
            {/* <MissionVisionVideo /> */} 
            <section className="Servicios">
                <div className="container">
                    <div className="services">
                        <h1>Nuestros <span>Servicios</span></h1>
                    </div>
                    <div className="service-row">
                        {serviceData.map((service, index) => (
                            <div className="service-box" key={index}>
                                <i className={`fa ${service.icon}`}></i>
                                <h3>{service.title} <span>{service.subtitle}</span></h3>
                                <p>{service.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>  
        </div>
    );
};

const serviceData = [
    {
        icon: 'fa-briefcase', // Updated icon for Strategic Consulting
        title: 'Consultoría Estratégica - Análisis de Mercado',
        description: 'Identificación de oportunidades para mejorar la retención de clientes y atraer nuevos consumidores.',
    },
    {
        icon: 'fa-briefcase', // Same icon for strategic consulting
        title: 'Consultoría Estratégica - Asesoría Financiera',
        description: 'Optimización de recursos y administración financiera para fomentar el crecimiento orgánico.',
    },
    {
        icon: 'fa-gavel', // Updated icon for Legal Advisory
        title: 'Asesoría Legal - Actas Constitutivas y Poderes',
        description: 'Gestión de documentos corporativos esenciales.',
    },
    {
        icon: 'fa-gavel', // Same icon for legal services
        title: 'Asesoría Legal - Registro de Marcas y Patentes',
        description: 'Protección de la propiedad intelectual.',
    },
    {
        icon: 'fa-gavel', // Same icon for legal services
        title: 'Asesoría Legal - Due Diligence Legales',
        description: 'Análisis detallado para minimizar riesgos.',
    },
    {
        icon: 'fa-gavel', // Same icon for legal services
        title: 'Asesoría Legal - Asesoría en Propiedad Intelectual',
        description: 'Protección y gestión de activos intangibles.',
    },
    {
        icon: 'fa-gavel', // Same icon for legal services
        title: 'Asesoría Legal - Elaboración de Contratos',
        description: 'Creación de contratos inmobiliarios y comerciales adaptados a tus necesidades.',
    },
    {
        icon: 'fa-gavel', // Same icon for legal services
        title: 'Asesoría Legal - Consultoría Ambiental',
        description: 'Asesoría en cumplimiento y normativas ambientales.',
    },
    {
        icon: 'fa-gavel', // Same icon for legal services
        title: 'Asesoría Legal - Gestión de Herencias en Inmuebles',
        description: 'Soporte legal en cuestiones patrimoniales.',
    },
    {
        icon: 'fa-gavel', // Same icon for legal services
        title: 'Asesoría Legal - Estructuracion juridica y laboral',
        description: 'Diseño de estructuras legales y contractuales eficientes que aseguren el cumplimiento normativo.',
    },
    {
        icon: 'fa-cogs', // Updated icon for Technological Development
        title: 'Desarrollo Tecnológico - Desarrollo de Aplicaciones Web',
        description: 'Construcción de plataformas personalizadas, desde sistemas de gestión hasta e-commerce.',
    },
    {
        icon: 'fa-cogs', // Same icon for technological development
        title: 'Desarrollo Tecnológico - Sistemas de Gestión Empresarial (ERP/CRM)',
        description: 'Implementación y personalización de sistemas para una gestión eficaz.',
    },
    {
        icon: 'fa-cogs', // Same icon for technological development
        title: 'Desarrollo Tecnológico - Automatización de Procesos',
        description: 'Herramientas que optimizan flujos de trabajo.',
    },
    {
        icon: 'fa-cogs', // Same icon for technological development
        title: 'Desarrollo Tecnológico - Inteligencia Artificial y Machine Learning',
        description: 'Soluciones para análisis predictivo y automatización de tareas.',
    },
    {
        icon: 'fa-cogs', // Same icon for technological development
        title: 'Desarrollo Tecnológico - Integración de APIs',
        description: 'Conexión e interoperabilidad entre plataformas.',
    },
    {
        icon: 'fa-cogs', // Same icon for technological development
        title: 'Desarrollo Tecnológico - Arquitectura en la Nube',
        description: 'Diseño y despliegue de infraestructura en servicios de nube.',
    },
    {
        icon: 'fa-cogs', // Same icon for technological development
        title: 'Desarrollo Tecnológico - Desarrollo de Sistemas de Lenguaje Natural (NLP)',
        description: 'Aplicaciones para el entendimiento y generación de lenguaje natural.',
    },
    {
        icon: 'fa-cogs', // Same icon for technological development
        title: 'Desarrollo Tecnológico - IoT (Internet de las Cosas)',
        description: 'Soluciones para recolección y análisis de datos en tiempo real.',
    },
    {
        icon: 'fa-cogs', // Same icon for technological development
        title: 'Desarrollo Tecnológico - Desarrollo de Soluciones Blockchain',
        description: 'Implementación de sistemas descentralizados y contratos inteligentes.',
    },
    {
        icon: 'fa-bullhorn', // Updated icon for Marketing
        title: 'Mercadotecnia - Estrategias de Marketing Digital',
        description: 'Creación de campañas efectivas en redes sociales y publicidad online.',
    },
    {
        icon: 'fa-bullhorn', // Same icon for marketing
        title: 'Mercadotecnia - Branding y Diseño de Marca',
        description: 'Desarrollo de la identidad visual de tu empresa.',
    },
    {
        icon: 'fa-bullhorn', // Same icon for marketing
        title: 'Mercadotecnia - Investigación de Mercado',
        description: 'Análisis para entender mejor a tu público objetivo y sus necesidades.',
    },
    {
        icon: 'fa-bullhorn', // Same icon for marketing
        title: 'Mercadotecnia - Optimización de Conversiones',
        description: 'Mejora de la experiencia del cliente para aumentar las ventas y la retención.',
    },
    {
        icon: 'fa-calculator', // Updated icon for Accounting and Financial Advisory
        title: 'Asesoría Contable y Financiera - Contabilidad Financiera',
        description: 'Servicios de contabilidad que aseguran el cumplimiento normativo y la gestión eficiente.',
    },
    {
        icon: 'fa-calculator', // Same icon for accounting and financial advisory
        title: 'Asesoría Contable y Financiera - Planificación Fiscal',
        description: 'Estrategias para optimizar la carga fiscal de tu empresa.',
    },
    {
        icon: 'fa-calculator', // Same icon for accounting and financial advisory
        title: 'Asesoría Contable y Financiera - Análisis Financiero',
        description: 'Evaluación de la salud financiera y elaboración de informes claros y útiles.',
    },
    {
        icon: 'fa-briefcase', // Updated icon for Labor Advisory
        title: 'Asesoría Laboral - Contratos y Reglamentos Internos',
        description: 'Asesoría en la elaboración y revisión de documentos laborales.',
    },
    {
        icon: 'fa-briefcase', // Same icon for labor advisory
        title: 'Asesoría Laboral - Litigio Laboral',
        description: 'Representación legal en conflictos laborales.',
    },
];



export default Servicios;
