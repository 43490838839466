import React from 'react';
import Slider from 'react-infinite-logo-slider';
import Carousel from '../../Components/Carousel';
import InfiniteSlider from '../../Components/InfiniteSlider';
import HomeInfo from '../../Components/HomeInfo';
import './Home.css';
import foto1 from "../../imagenes/slider_img/foto1.png"
import foto2 from "../../imagenes/slider_img/LogosLetras-01.png"

const Home = (props) => {
    return (
        <div>
            <Carousel fotos={props.data.Carousel} />
            <HomeInfo />
            {/* <InfiniteSlider className="home-slider">
                <Slider.Slide className="home-slider">
                    <img src={foto2} alt="foto 1" className='slider-image' />
                </Slider.Slide>
                <Slider.Slide className="home-slider">
                    <img src={foto1} alt="foto 1" className='slider-image' />
                </Slider.Slide>
                <Slider.Slide className="home-slider">
                    <img src={foto2} alt="foto 1" className='slider-image' />
                </Slider.Slide>
                <Slider.Slide className="home-slider">
                    <img src={foto1} alt="foto 1" className='slider-image' />
                </Slider.Slide>
            </InfiniteSlider> */}
        </div>
    );
};

export default Home;
