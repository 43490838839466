import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import ScrollToTop from "./Components/ScrollTop";
import JsonData from "./Data/data.json";
import Home from './Pages/Home/Home';
import Servicios from './Pages/Servicios/Servicios';
import AboutUs from './Pages/About_Us/About_Us';
import Contact from './Pages/Contact/Contact';
import NotFound from './Pages/NotFound/NotFound';
import Privacy from "./Pages/Privacy/Privacy";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header";

function App() {
  const [Data] = useState(JsonData);

  return (
    <BrowserRouter>
      <Header />
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home data={Data.home} />} />
          <Route path="/about_us" element={<AboutUs />} />
          <Route path="/servicios" element={<Servicios />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ScrollToTop>
      <Footer />
    </BrowserRouter>
  );
}

export default App;