import React from 'react';
import './Privacy.css';

const PrivacyPolicy = () => {
    return (
        <div className='Privacy_Document'>
            <h2>AVISO DE PRIVACIDAD</h2>
            
            <p>
                <strong></strong> Con domicilio en “Av. P.° de la Reforma 284, Juárez, Cuauhtémoc, 06600, Ciudad de México, CDMX.” es responsable de los datos personales que recaba por sí mismo y en respeto al derecho de privacidad y a la autodeterminación informativa de las personas en cumplimiento con lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de Particulares (LFPDPPP) y su Reglamento, atendiendo los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad.
            </p>
            
            <p>
                <strong></strong> Asegura y garantiza estricta confidencialidad y establece las medidas de seguridad administrativas, físicas y técnicas necesarias en la recabación y tratamiento de sus datos personales (<strong>No. de celular, RFC, dirección, CURP, No. de licencia, NSS, clave de elector, firma, fotografías, domicilio, huella digital, entre otros</strong>); y sensibles (<strong>estado de salud, peso, estatura, domicilio y ocupación de padres, hijos, cónyuges, mascotas, intereses</strong>) que usted nos proporcione directamente o a través de la solicitud en la página web o aplicación.
            </p>
            
            <p>
                De acuerdo con los Art. 40, 41 y 42 del Reglamento de LFPDPPP sus datos personales serán utilizados para establecer la relación jurídica con la organización: actividades de administración interna, <strong>actividades comerciales y operativas, actividades de integración y desarrollo de personal, administración de clientes y servicios</strong>.
            </p>
            
            <p>
                Los <strong>datos personales</strong> pueden ser transferidos y tratados por terceros particulares (Bancos, Certificadoras, Servicios Profesionales, clientes y/o entre otras para las finalidades contenidas en el Art. 37 de la Ley), por lo que no es necesario obtener el consentimiento para su transferencia.
            </p>
            
            <p>
                Los datos personales <strong>sensibles</strong> pueden ser transferidos a terceros particulares (Servicios de emergencia médica, Aseguradoras, entre otros) para las finalidades contenidas en el Art. 37 de la Ley previo consentimiento de la persona. El consentimiento por escrito se solicitará al recabar sus datos sensibles en el registro como usuario y en este aviso.
            </p>
            
            <p>
                <strong>EJERCICIO DE LOS DERECHOS ARCO</strong><br />
                Con gusto daremos cumplimiento a la solicitud que recibamos del titular o de su representante legal por la cual requiera hacer uso de sus derechos al <strong>Acceso, Rectificación, Cancelación u Oposición de sus datos personales</strong>, puede enviar vía correo electrónico solicitud por escrito a la dirección electrónica: tifexcontact@gmail.com.
            </p>
            
            <p>
                Por motivos de seguridad la empresa cuenta con cámaras en sus oficinas corporativas y ubicaciones en las que tenga presencia permanente o provisional, por lo que le comunicamos que usted estará siendo grabado mientras permanezca en ellas.
            </p>
            
            <p>
                <strong>NO</strong> realiza transferencias de Datos Personales a Terceros, salvo las excepciones previstas en los artículos 37 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares y se compromete a resguardar sus documentos, datos personales e imágenes grabadas en sus instalaciones, cualquiera que sea la naturaleza de estos, haciendo un buen uso de los mismos, teniendo por seguro que lo más importante es el crecimiento conjunto de la familia organizacional y la empresa.
            </p>

            <p>Para más información acerca del tratamiento de los datos y derechos que puede usted hacer valer favor de consultar el <strong>AVISO DE PRIVACIDAD</strong> a través de la página web Tifex.mx o solicitarla al correo: tifexcontact@gmail.com</p>
            
            <div className="last-modified">
                <p><strong>Fecha de última modificación: Octubre de 2024</strong></p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
