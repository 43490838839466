import React from 'react';
import './MissionVisionVideo.css';
import MX_4 from '../imagenes/Mision.jpg';
import MX_5 from '../imagenes/Vision.jpg';

const MissionVisionVideo = () => {
    return (
        <div className="page-container">
            <section className="mission">
                <div className="text-left">
                    <h2>Misión</h2>
                    <div className="paragraph">
                        En Tifex, nuestra misión es empoderar a empresas en crecimiento mediante la implementación de estrategias personalizadas que optimicen sus recursos, fortalezcan su base legal y mejoren su competitividad en el mercado.
                    </div>
                    <div className="paragraph">
                        Nos comprometemos a acompañar a nuestros clientes en cada etapa de su desarrollo, facilitando su transformación en organizaciones sostenibles y exitosas.                     
                    </div>
                </div>
                <img src={MX_4} alt="Misión" className="image-right" />
            </section>

            <section className="vision">
                <img src={MX_5} alt="Visión" className="image-left" />
                <div className="text-right">
                    <h2>Visión</h2>
                    <div className="paragraph">
                        Ser la consultoría líder en el acompañamiento a empresas en crecimiento en el mercado hispanohablante, reconocida por nuestra capacidad de generar resultados tangibles y duraderos.
                    </div>
                    <div className="paragraph">
                        Aspiramos a ser el socio estratégico preferido por aquellas empresas que buscan innovar, expandir su mercado y alcanzar su máximo potencial.
                    </div>
                </div>
            </section>
        </div>
    );
};

export default MissionVisionVideo;
