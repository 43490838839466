import React from 'react';
import './MissionVisionVideo.css';
import Inicio_1 from '../imagenes/Inicio_1.jpeg';
import Inicio_2 from '../imagenes/Inicio_2.jpeg';
import Inicio_3 from '../imagenes/Inicio_3.jpeg';
import { FaArrowRightLong } from "react-icons/fa6";
import ScrollToTopLink from '../Components/ScrollTopLink';
import { WhatWeDoGrid } from './WhatWeDoGrid/WhatWeDoGrid';

const HomeInfo = () => {
    return (
        <div className="page-container">
            <section className="Inicio1">
                <img src={Inicio_1} alt="Inicio1" className="image-left" />
                <div className="text-right">
                    <h2>Tu Socio en el Crecimiento Empresarial</h2>
                    <div className="paragraph">
                        En Tifex, entendemos que el camino del crecimiento empresarial puede ser desaﬁante, especialmente para aquellas empresas que han experimentado un rápido ascenso. Si tu negocio ha pasado de ser pequeño a mediano de la noche a la mañana, o si eres una empresa mediana que se siente estancada y busca nuevas oportunidades, estás en el lugar correcto.
                    </div>
                </div>
            </section>
            <section className="Inicio2">
                <div className="text-left">
                    <h2>Nuestra Misión</h2>
                    <div className="paragraph">
                        Nuestra misión es acompañarte en cada paso del proceso de crecimiento. Nos especializamos en ofrecer soluciones personalizadas para empresas que desean fortalecer su base y explorar nuevas oportunidades. A través de un enfoque integral, te ayudamos a sentar las bases necesarias para un crecimiento sostenible y exitoso.
                    </div>
                </div>
                <img src={Inicio_2} alt="Visión" className="image-right" />
            </section>
            <WhatWeDoGrid />
            <section className="Inicio3">
                <img src={Inicio_3} alt="Visión" className="image-left" />
                <div className="text-right">
                    <h2>Nuestro Compromiso</h2>
                    <div className="paragraph">
                        En Tifex, nos comprometemos a ofrecerte un servicio cercano, personalizado y adaptado a las necesidades especíﬁcas de tu negocio. Creemos que cada empresa tiene un potencial único y estamos aquí para ayudarte a descubrirlo.
                    </div>
                </div>
            </section>
            
            <section className="Inicio5">
                <div className="text">
                    <h2>¿Listo para Dar el Siguiente Paso?</h2>
                    <div className="paragraph">
                        Si estás listo para llevar tu empresa al siguiente nivel, contáctanos. Juntos, diseñaremos una estrategia que no solo impulse tu crecimiento, sino que también te permita consolidar tu lugar en el mercado.
                    </div>
                    <div className="paragraph">
                        Tifex, donde el crecimiento sostenible comienza
                    </div>
                    <div className="paragraph">
                        <ScrollToTopLink to="/contact" className='contact-button'>
                                Contactanos
                                <FaArrowRightLong className='right-arrow'/>
                        </ScrollToTopLink>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default HomeInfo;
