import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Carousel.css';
import Portada_1 from '../imagenes/Portada_1.jpg';
import Portada_2 from '../imagenes/Portada_2.jpg';
import Portada_3 from '../imagenes/Portada_3.jpg';
import Portada_4 from '../imagenes/Portada_4.jpg';

const Carousel = (props) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    return (
        <div className="carousel-container">
            <Slider {...settings}>
                <div className="carousel-slide">
                    <div className="overlay"></div>
                    <img src={Portada_1} alt="Slide 1" />
                    <div className="carousel-text">
                        <h1>“Cada vez que veas un negocio exitoso, fue porque alguna vez alguien tomó una decisión valiente” - Peter Drucker</h1>
                    </div>
                </div>
                <div className="carousel-slide">
                <div className="overlay"></div>
                    <img src={Portada_2} alt="Slide 2" />
                    <div className="carousel-text">
                        <h1>“Si tu negocio no está en internet, tu negocio no existe” - Bill Gates</h1>
                    </div>
                </div>
                <div className="carousel-slide">
                <div className="overlay"></div>
                    <img src={Portada_3} alt="Slide 3" />
                    <div className="carousel-text">
                        <h1>“Creo que si haces algo y sale bien, luego tienes que hacer algo maravilloso.” - Steve Jobs</h1>
                    </div>
                </div>
                <div className="carousel-slide">
                <div className="overlay"></div>
                    <img src={Portada_4} alt="Slide 4" />
                    <div className="carousel-text">
                        <h1>“En un mercado de gran actividad, no destacarse es lo mismo que ser invisible”-  Seth Godin</h1>
                    </div>
                </div>
            </Slider>
        </div>
    );
};

export default Carousel;
