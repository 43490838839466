// ScrollToTopLink.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const ScrollToTopLink = ({ to, children,  isMenuOpen, /*setIsMenuOpen,*/ ...rest }) => {
    const { pathname } = useLocation();
    
    const handleClick = (event) => {
        if (to === pathname) {
            event.preventDefault();
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        // setIsMenuOpen(false)
    };

    return (
        <Link to={to} onClick={handleClick} {...rest}>
            {children}
        </Link>
    );
};

export default ScrollToTopLink;
