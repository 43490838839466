import React from "react";
import { FaChartLine, FaMoneyBillWave, FaBalanceScale, FaLaptopCode } from 'react-icons/fa';
import "./WhatWeDoGrid.css"

export const WhatWeDoGrid = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>¿Qué Hacemos?</h2>
        </div>
        <div className="row">
            <div key="1" className="item">
                <FaChartLine className="icon"/>
                <div className="service-desc">
                    <h3>Análisis de Mercado</h3>
                    <p>Nuestro equipo investiga a fondo tu mercado para identiﬁcar cómo mejorar la retención de tus clientes actuales y atraer a nuevos consumidores. Generamos estrategias que crean inercia y te permiten aprovechar las oportunidades emergentes.</p>
                </div>
            </div>
            <div key = "2" className="item">
                <FaMoneyBillWave className="icon"/>
                <div className="service-desc">
                    <h3>Asesoría Financiera</h3>
                    <p>Revisamos y optimizamos la administración de tus recursos ﬁnancieros. A través de un análisis detallado, buscamos áreas donde puedes ahorrar inteligentemente, facilitando un crecimiento orgánico y sostenible.</p>
                </div>
            </div>
            <div key = "3" className="item">
                <FaBalanceScale className="icon"/>
                <div className="service-desc">
                    <h3>Fortalecimiento Legal</h3>
                    <p>Ayudamos a establecer un sólido gobierno corporativo, minimizando riesgos legales y preparando tu empresa para el futuro. Queremos que te enfoques en crecer, sin preocupaciones por problemas legales.</p>
                </div>
            </div>
            <div key = "4" className="item">
                <FaLaptopCode className="icon"/>
                <div className="service-desc">
                    <h3>Transformación Digital</h3>
                    <p>Modernizamos tus procesos a través de la implementación de nuevas tecnologías. Con nuestra orientación, podrás optimizar operaciones, aumentar la eﬁciencia y mantenerte competitivo en un mercado en constante cambio.</p>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};