import React from 'react';
import './NotFound.css';

const NotFound = () => {
    return (
        <div className="NotFound">
            <h1>404 - Página no encontrada</h1>
            <p>La página que estás buscando podría haber sido eliminada, cambiado su nombre o estar temporalmente no disponible.</p>
            <a href="/">Ir a la página de inicio</a>
        </div>
    );
};

export default NotFound;