import React from "react";
import {
  FaPhoneAlt,
  FaFacebookSquare,
  FaInstagram,
  FaTiktok,
  FaLinkedin,
  FaWhatsapp
} from "react-icons/fa";
import { MdMailOutline } from "react-icons/md";
import { LuAtSign } from "react-icons/lu";
import { RiMapPinLine } from "react-icons/ri";

const Contact = () => {
  return (
    <div id="contact" className="text-center">
      <div className="container">
        <div className="contact-grid">
          <div className="contact-item">
            <FaPhoneAlt className="icon" />
            <div className="service-desc">
              <h3>Teléfono</h3>
              <ul className="social-media-list">
                <li>
                  <a href="https://wa.me/525518173107" target="_blank" rel="noopener noreferrer">
                    <FaWhatsapp className="social-icon" />
                    <span>(55) 1817 3107</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="contact-item">
            <MdMailOutline className="icon" />
            <div className="service-desc">
              <h3>Correo</h3>
              <a href="mailto:tifexcontact@gmail.com">
                tifexcontact@gmail.com
              </a>
            </div>
          </div>
          <div className="contact-item">
            <RiMapPinLine className="icon" />
            <div className="service-desc">
              <h3>Dirección</h3>
              <p>
                <a href="https://maps.app.goo.gl/fmu8GN2uELVwLZp27" target="_blank" rel="noopener noreferrer">Av. P.° de la Reforma 284, Juárez, Cuauhtémoc, 06600, Ciudad de México, CDMX</a>
              </p>
            </div>
          </div>
          <div className="contact-item">
            <LuAtSign className="icon" />
            <div className="service-desc">
              <h3>Redes Sociales</h3>
              <ul className="social-media-list">
                <li>
                  <a href="https://www.linkedin.com/company/105250595/admin/dashboard/" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin className="social-icon" />
                    <span>Tifex</span>
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/people/TIFEX-MX/61565655134633/" target="_blank" rel="noopener noreferrer">
                    <FaFacebookSquare className="social-icon" />
                    <span>Tifex</span>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/tifexmx" target="_blank" rel="noopener noreferrer">
                    <FaInstagram className="social-icon" />
                    <span>@tifexmx</span>
                  </a>
                </li>
                <li>
                  <a href="https://www.tiktok.com/@tifexmx?lang=es" target="_blank" rel="noopener noreferrer">
                    <FaTiktok className="social-icon" />
                    <span>@tifexmx</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
