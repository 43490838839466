import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }) => {
    const { pathname, hash } = useLocation();
    
    useEffect(() => {
        if (hash === '') {
            window.scrollTo(0, 0);
        } else {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [pathname, hash]);

    return children || null;
};

export default ScrollToTop;