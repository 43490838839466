import React from 'react';
import MissionVisionVideo from '../../Components/MissionVisionVideo'; 
import './About_Us.css';
import MX_6 from '../../imagenes/Valores.jpg';

const AboutUs = () => {
    return (
        <div>
            <MissionVisionVideo />
            <section className="valores">
                <div className="text-left">
                    <h2>Valores</h2>
                    <ul className="values-list">
                        <li><strong>Compromiso:</strong> Estamos dedicados al éxito de nuestros clientes y nos involucramos profundamente en cada proyecto, ofreciendo un servicio de alta calidad.</li>
                        <li><strong>Integridad:</strong> Actuamos con transparencia y honestidad en todas nuestras interacciones, construyendo relaciones basadas en la confianza.</li>
                        <li><strong>Innovación:</strong> Fomentamos una cultura de creatividad y apertura a nuevas ideas, buscando siempre las mejores soluciones para nuestros clientes.</li>
                        <li><strong>Colaboración:</strong> Creemos en el poder del trabajo en equipo, tanto dentro de nuestra consultoría como con nuestros clientes, para lograr objetivos comunes.</li>
                        <li><strong>Excelencia:</strong> Nos esforzamos por superar las expectativas, ofreciendo un servicio excepcional y resultados que marcan la diferencia.</li>
                        <li><strong>Sostenibilidad:</strong> Promovemos prácticas empresariales responsables que no solo benefician a nuestros clientes, sino que también contribuyen al bienestar de la comunidad y el medio ambiente.</li>
                    </ul>
                </div>
                <img src={MX_6} alt="Valores" className="image-right"/>
            </section>
        </div>
    );
};

export default AboutUs;
